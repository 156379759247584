<template>
  <div>
    <div class="Zhanpingbanner" ref="shejihd">
      <img class="Zhanpingbannerpc" :src="img" alt="" />
      <img class="Zhanpingbannerios" :src="imgios" alt="" />
    </div>
    <div class="shejihd">
      <!-- // 活动类型 沙龙等  暂时搁置 -->
      <el-tabs v-model="activeName" @tab-click="handleClick" v-if="false">
        <el-tab-pane
          v-for="(tab, index) in activeall"
          :key="index"
          :label="tab.label"
          :name="tab.name"
        >
          <div class="Zhanping_shai">
            <div class="Zhanping_shaitext">筛选</div>
            <div>
              <el-select v-model="optionsvalue" placeholder="活动形式">
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select v-model="moneyval" placeholder="付费类型">
                <el-option
                  v-for="(item, index) in money"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select v-model="paixuval" placeholder="排序">
                <el-option
                  v-for="(item, index) in paixu"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select v-model="hdtypeval" placeholder="活动状态">
                <el-option
                  v-for="(item, index) in hdtype"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <ul class="shejihdul">
            <li
              class="shejihdulli"
              v-for="(item, index) in shejihdulall"
              :key="index"
              @click="shejihdulliclick(item.a_id)"
            >
              <div class="shejihdulli_img">
                <img class="shejihdulli_imgimg" :src="item.title_pic" alt="" />
                <div class="shejihdulli_imgtype" v-if="item.a_type == 1">
                  沙龙活动
                </div>
                <div class="shejihdulli_imgtype" v-if="item.a_type == 2">
                  竞赛活动
                </div>
                <div class="shejihdulli_imgtype" v-if="item.a_type == 3">
                  展览活动
                </div>
                <div class="shejihdulli_imgtype" v-if="item.a_type == 4">
                  专题活动
                </div>
                <div
                  class="shejihdulli_money"
                  v-if="item.pay_state == 0 ? false : true"
                >
                  <div class="shejihdulli_moneytype">收费</div>
                </div>
              </div>
              <div class="shejihdulli_title">
                <div>{{ item.a_type == 1 ? "线上活动" : "线下活动" }}</div>
                <p>{{ item.title }}</p>
              </div>
              <div class="shejihdulli_time">
                <p class="shejihdulli_timep">
                  {{ item.draw_start }} ~ {{ item.draw_end }}
                </p>

                <div class="shejihdulli_timetype">
                  <img
                    v-if="item.draw_zt == 1"
                    src="https://task.hozoin.cn/Works/20210105/sj-0625411431.png"
                    alt=""
                  />
                  <img
                    v-if="item.draw_zt != 1"
                    src="https://task.hozoin.cn/Works/20210105/sj-0843395723.png"
                    alt=""
                  />
                  <span>{{ item.draw_zt == 1 ? "进行中" : "已结束" }}</span>
                </div>
              </div>
            </li>
            <li class="lizero" v-if="lizero">
              <img
                src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
                alt=""
              />
            </li>
          </ul>
          <el-pagination
            :pager-count="5"
            @current-change="currentchange"
            :page-size="pagesize"
            background
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
      <!-- // 活动类型 沙龙等  暂时搁置 -->
      <ul class="shejihdul" style="margin-top: 2rem">
        <li
          class="shejihdulli"
          v-for="(item, index) in shejihdulall"
          :key="index"
          @click="shejihdulliclick(item.a_id)"
        >
          <div class="shejihdulli_img">
            <img class="shejihdulli_imgimg" :src="item.title_pic" alt="" />
            <div
              class="shejihdulli_money"
              v-if="item.pay_state == 0 ? false : true"
            >
              <div class="shejihdulli_moneytype">收费</div>
            </div>
          </div>
          <div class="shejihdulli_title">
            <div>{{ item.a_type == 1 ? "线上活动" : "线下活动" }}</div>
            <p>{{ item.title }}</p>
          </div>
          <div class="shejihdulli_time">
            <p class="shejihdulli_timep">
              {{ item.draw_start }} ~ {{ item.draw_end }}
            </p>

            <div class="shejihdulli_timetype">
              <img
                v-if="item.draw_zt == 1"
                src="https://task.hozoin.cn/Works/20210105/sj-0625411431.png"
                alt=""
              />
              <img
                v-if="item.draw_zt != 1"
                src="https://task.hozoin.cn/Works/20210105/sj-0843395723.png"
                alt=""
              />
              <span>{{ item.draw_zt == 1 ? "进行中" : "已结束" }}</span>
            </div>
          </div>
        </li>
        <li class="lizero" v-if="lizero">
          <img
            src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
            alt=""
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      lizero: false,
      img: "https://task.hozoin.cn/Works/20210204/sj-3543001249.png",
      imgios: "https://task.hozoin.cn/Works/20210310/sj-5096581314.png",
      activeName: "0",
      pagesize: 20,
      total: 0,
      activeall: [
        {
          label: "全部",
          name: "0",
        },
        {
          label: "沙龙",
          name: "1",
        },
        {
          label: "竞赛",
          name: "2",
        },
        {
          label: "展评",
          name: "3",
        },
        {
          label: "专题",
          name: "4",
        },
      ],

      options: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "1",
          t: "线上",
        },
        {
          value: "2",
          t: "线下",
        },
      ],
      money: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "1",
          t: "免费",
        },
        {
          value: "2",
          t: "收费",
        },
      ],
      paixu: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "2",
          t: "热度",
        },
        {
          value: "1",
          t: "时间",
        },
      ],
      hdtype: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "1",
          t: "进行中",
        },
        {
          value: "2",
          t: "已结束",
        },
      ],
      optionsvalue: "",
      moneyval: "",
      paixuval: "",
      hdtypeval: "",
      shejihdulall: [],
      userinfo: {},
    };
  },
  methods: {
    handleClick(tab, event) {
      this.sx(1);
    },
    shejihdulliclick(id) {
      this.$router.push({ path: "/shejihd_c", query: { data: id } });
    },
    sx(page) {
      var that = this;
      that.shejihdulall = [];
      this.$axios({
        url: "/api/getActivityList",
        method: "post",
        data: {
          a_type: that.activeName,
          a_shape: that.optionsvalue,
          is_sorting: that.paixuval,
          is_money: that.moneyval,
          is_ongoing: that.hdtypeval,
          page,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lizero = false;
          that.shejihdulall = res.data.result.list;
          that.total = res.data.result.count;
        } else {
          that.lizero = true;
        }
      });
    },
    currentchange(page) {
      document.documentElement.scrollTop = 0;
      this.sx(page);
    },
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.$axios({
      url: "/api/getActivityList",
      method: "post",
      data: {
        a_type: "",
        page: "1",
        limit: that.pagesize,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.shejihdulall = res.data.result.list;
        that.total = res.data.result.count;
      } else {
        console.log("请求失败");
      }
    });
  },
  watch: {},
};
</script>
<style>
@import "../assets/css/shejihd.css";
</style>
